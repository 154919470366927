*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.navbar-sm {
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-image: none;
  border-radius: 1rem;
}

.bg-dark {
  background: linear-gradient(150deg, #151515 0%, #09090a 51.56%, #151515 100%);
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.left-2\/4 {
  left: 50%;
}

.top-5 {
  top: 1.25rem;
}

.top-full {
  top: 100%;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.list-none {
  list-style-type: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-5 {
  line-height: 1.25rem;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: url("Texture.4d2e9879.png"), linear-gradient(90deg, #151515 10%, #354701 75.68%, #151515 100%);
  margin: 0;
  font-family: Manrope, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

h1 {
  letter-spacing: -.82px;
  font-family: Manrope;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
}

h2 {
  letter-spacing: -.82px;
  font-family: Manrope;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.5rem;
}

@media (width >= 768px) {
  h1 {
    letter-spacing: -.82px;
    font-family: Manrope;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.5rem;
  }
}

.line-container {
  justify-content: center;
  display: flex;
}

.contact {
  justify-content: flex-end;
  margin: 0 150px;
  display: flex;
}

.project-card {
  border-bottom: 1px solid #fff;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 100px;
  padding: 60px 0;
  display: flex;
}

.project-name {
  margin: 0;
  font-family: Voyage, "sans-serif";
  font-size: 160px;
  font-weight: 100;
}

.project-info-container {
  width: 50%;
  font-family: Raleway, "sans-serif";
}

.featured-work-heading {
  border-bottom: 1px solid #fff;
  margin: 20px 50px;
  padding-top: 100px;
  font-family: Raleway, "sans-serif";
}

.main-project-section {
  color: #efefed;
  background: #1e1e1e;
}

.span-role {
  color: #ecb424;
  font-size: 20px;
}

.seeWorkBtn {
  color: #efefed;
  background: #1e1e1e;
  border: 1px solid #fff;
  border-radius: 20px;
  margin: 20px 0;
  padding: 10px 30px;
  font-family: Raleway, "sans-serif";
  font-size: 14px;
}

@media (width >= 768px) {
  .md\:navbar-lg {
    --tw-backdrop-blur: blur(24px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    background: linear-gradient(173deg, #ffffff4d 0%, #ffffff1a 100%);
    border-radius: 1rem;
  }

  .md\:visible {
    visibility: visible;
  }

  .md\:relative {
    position: relative;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:mt-36 {
    margin-top: 9rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-11 {
    gap: 2.75rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:bg-none {
    background-image: none;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-44 {
    padding-bottom: 11rem;
  }

  .md\:pb-72 {
    padding-bottom: 18rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-28 {
    padding-top: 7rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }
}
/*# sourceMappingURL=index.9cad6230.css.map */
